import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import BannerLanding from '../components/BannerLanding'
import Layout from '../components/layout'
import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet
            title="References"
            meta={[
                { name: 'description', content: 'References Page' },
                { name: 'keywords', content: 'References Page' },
            ]}
        >
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Background</h2>
                    </header>
                      <p>Digital Transformation at the enterprise level can only be achieved with solid foundations from which to deliver and support new applications, services, and technologies. Several new paradigms like DevOps, infrastructure  as software, cloud, microservices, dockerization, etc, are widely seen as the answer to this problem, but implementing these paradigms represents a major challenge for large organizations because they do not always have the knowledge, process, technology or culture to facilitate the required IT change.</p>

<p><strong>Nubizzi</strong> provides our clients with practical and tangible solutions that have a rapid and significant positive impact. We understand how these technologies and practices can be successfully applied in modern organizations rather than just having theoretical knowledge from the classroom. Some of the work that we have done in specific vertical markets is detailed below.
                      </p>
                    <hr />
                   <header className="major">
                     <h3>RSI: cloud migration of core backing solution</h3>
                   </header>
                     <Link to="/generic" className="image top">
                        <img src={pic08} alt="" height="100"/>
                     </Link>
                     <br /><br />
                     <h4>Objective:</h4>
                       <div className="box">
                        <p>Migrate RSI core backing solution (named Abside) to an architecture that could be deployed with agility in private and public clouds</p>
                       </div>
                     <h4>Solution:</h4>
                        <ul><ul><li>The different components of the banking solution were packed in a set of 22 docker images and all the integration between the different components was automated.</li>
                        <li>The docker images include the front-end with several J2EE components using wildfly and mysql databases as well as the backend with tuxedo, db2 and cobol images</li>
                        <li>The application was deployed using DC/OS both in the RSI datacenters as in AWS</li>
                        <li>As subproduct of the project, the dockering of the components allowed to execute the whole solution in a single desktop machine.</li></ul></ul> 
                     <h4>Benefits:</h4>
                                <ul><ul><li>Complete automatization of the environment deployment process</li>
                                <li>Time reduction to create a new environment: <strong>from weeks to minutes</strong></li>
                                <li>Architecture that allows automatic scaling to business demands</li>
                                <li>Creation of personal development and testing environments, eliminating the need for shared environments</li>
                                <li>Creation of demo environments running on laptops, fully functional even without internet connexion</li>
                                <li>Documentation of all the installation process and the different dependencies between components</li></ul></ul>
                     <ul className="actions">
                        <li><Link to="/solutions" className="button small">Learn more</Link></li>
                    </ul>
                    <hr />
                   <header className="major">
                     <h3>eID: Architecture of VideoID</h3>
                   </header>
                     <Link to="/generic" className="image top">
                        <img src={pic09} alt="" />
                     </Link>
                     <br /><br />
                     <h4>Objective:</h4>
                        <div className="box">
                               <p>Develop and deploy an architecture to provide a high criticality multi-tenant SaaS service.<br /><br />
                               The solution had to have the following characteristics:
                               <ul><ul><li>Reduce lock-in with the cloud provider</li>
                               <li>High availability without single points of failure to provide a SaaS of high criticality</li>
                               <li>Agility to deploy new releases</li>
                               <li>Migration to a microservice architecture</li></ul></ul></p>
                        </div>
                     <h4>Solution:</h4>
                                <ul><ul><li>The application has been dockerized into several microservices which are orchestrated with DC/OS</li>
                                <li>The solution is deployed in client data centers in an on-premise private solution model</li>
	                        <li>...as well as in AWS and Azure to serve different clients in a multi-tenant SaaS model</li></ul></ul> 
                     <h4>Benefits:</h4>
                                <ul><ul><li>Flexibility in how the solution is sold: as multi-tenant SaaS or as licensed software</li>
                                <li>Flexibility in how the solution is deployed: into the cloud (AWS/Azure) or on-premise</li>
                                <li>Guaranteed high availability: after two years in production no service down-time</li>
                                <li>Hot deployments in production of new releases</li>
                                <li>Proved scalability with the continuous incorporation of clients on top of the multi-tenant solution</li></ul></ul>
                     <ul className="actions">
                       <li><Link to="/solutions" className="button small">Learn more</Link></li>
                     </ul>
                    <hr />
                   <header className="major">
                     <h3>Creation of Software as Service (SaaS) from traditional IP</h3>
                   </header>
                    <h4>Customer:</h4>
	                <p>Multinational IT company</p>
                    <h4>Objective:</h4>
	                        <div className="box">
	                               <p>Convert a software solution owned by the client in a Software as a Service run from the cloud.<br /><br />
	                               The solution had to have the following characteristics:
                               <ul><ul><li>Transaction-based cost</li>
	                               <li>High availability</li>
	                               <li>Guarantee scalability</li>
	                               <li>Multi-tenant</li>
	                               <li>Minimal enrolment and integration process</li>
	                        </ul></ul></p>
	                        </div>
	                     <h4>Solution:</h4>
	                                <ul><ul><li>The application was completed refactored and converted into a cloud-native application offering a RESTful API to the potential clients</li>
	                                <li>We use microservices for the core of the functionality</li>
	                                <li>We leverage AWS managed services for infrastructure and auxiliary services: DynamoDB, RDS, S3, ECS, EC2, Route53, VPC, ELB, EFS, Lambda, API Gateway, CloudFormation, CloudWatch, Certificate Manager, IAM, Athena, QuickSight, SQS and CloudFront</li></ul></ul>
	                     <h4>Benefits:</h4>
	                                <ul><ul><li>Easier to sell as a cost per transaction can be provided (compare with a cost per capacity of the previous model)</li>
	                                <li>Continuous innovation: new functionality is incorporated into the solution at a much faster pace that it was previously possible</li>
	                                <li>Scalability and adaptability to the demand, which has allowed to incorporate both small and very large customers</li></ul></ul>

                     <ul className="actions">
                        <li><Link to="/generic" className="button small">Learn more</Link></li>
                     </ul>
        </div>
            </section>
        </div>

    </Layout>
)

export default Landing
